import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import assetsCluster from "../../assets/AssetsCluster.svg";
import { getBatteryIcon } from "../../lib/getBatteryIcon";
import { getGoogleMapsApiKey } from "../../lib/getEnvVariables";
import { getLocalTime } from "../../pages/Projects/ProjectDetails.helper";
import "./GoogleMaps.css";
var Time;
(function (Time) {
    Time[Time["milli_Sec_500"] = 500] = "milli_Sec_500";
})(Time || (Time = {}));
const CustomGoogleMap = ({ addressDetails, mapCenter, selectedAddress, siteOffset, siteTimezone }) => {
    const [map, setMap] = useState(null);
    const [mapType, setMapType] = useState("");
    const [infoWindowOpen, setInfoWindowOpen] = useState(null);
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [mapDelay, setMapDelay] = useState(false);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: getGoogleMapsApiKey()
    });
    const onLoad = useCallback(function callback(map) {
        const bounds = new google.maps.LatLngBounds();
        addressDetails.forEach((details) => {
            bounds.extend({
                lat: Number(details.location[0]),
                lng: Number(details.location[1])
            });
        });
        map.fitBounds(bounds);
        setMap(map);
    }, [addressDetails]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setMapDelay(true);
        }, Time.milli_Sec_500);
        return () => clearTimeout(timer);
    }, []);
    const getBoundsZoomLevel = (bounds, mapDim) => {
        const WORLD_DIM = { height: 256, width: 256 };
        const ZOOM_MAX = 21;
        function latRad(lat) {
            const ONE_HUNDRED_EIGHTY = 180;
            const sin = Math.sin((lat * Math.PI) / ONE_HUNDRED_EIGHTY);
            const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }
        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
        const lngDiff = ne.lng() - sw.lng();
        const THREE_HUNDRED_SIXTY = 360;
        const lngFraction = (lngDiff < 0 ? lngDiff + THREE_HUNDRED_SIXTY : lngDiff) / THREE_HUNDRED_SIXTY;
        const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    };
    useEffect(() => {
        if (map) {
            const handleZoomChange = () => {
                setInfoWindowOpen(null);
                setHoveredMarker(null);
            };
            const zoomChangeListener = map.addListener("zoom_changed", handleZoomChange);
            return () => {
                google.maps.event.removeListener(zoomChangeListener);
            };
        }
    }, [map]);
    useEffect(() => {
        if (map && selectedAddress && selectedAddress.addressList) {
            const { addressList = [] } = selectedAddress;
            if (addressList.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                addressDetails.forEach((details) => {
                    if (addressList.indexOf(details.assetId) >= 0) {
                        bounds.extend({
                            lat: Number(details.location[0]),
                            lng: Number(details.location[1])
                        });
                    }
                });
                const mapElement = document.getElementById("google-map");
                const mapDim = {
                    height: mapElement?.offsetHeight,
                    width: mapElement?.offsetWidth
                };
                const zoom = addressList.length === 1
                    ? getBoundsZoomLevel(bounds, mapDim)
                    : getBoundsZoomLevel(bounds, mapDim) - 1;
                map.setZoom(zoom);
                map.setCenter(bounds.getCenter());
            }
            else {
                map.setZoom(8);
                map.panTo(mapCenter);
            }
        }
    }, [selectedAddress, map, mapCenter]);
    const renderMarkers = (clusterer) => addressDetails.map((details, index) => (_jsx(Marker, { clusterer: clusterer, "data-test": `marker-${index + 1}`, icon: {
            scaledSize: new window.google.maps.Size(60, 60),
            url: details.iconUrl
        }, label: {
            className: "gm-map-marker-label",
            fontSize: "16px",
            fontWeight: "bold",
            text: details.assetName
        }, onMouseOut: () => {
            setHoveredMarker(null);
        }, onMouseOver: () => {
            setHoveredMarker(details);
        }, position: {
            lat: details.location[0],
            lng: details.location[1]
        } }, index)));
    useEffect(() => {
        let timer;
        const ONE_HUNDRED_FIFTY = 150;
        if (hoveredMarker) {
            timer = setTimeout(() => {
                setInfoWindowOpen(hoveredMarker);
            }, ONE_HUNDRED_FIFTY);
        }
        else {
            timer = setTimeout(() => {
                setInfoWindowOpen(null);
            }, ONE_HUNDRED_FIFTY);
        }
        return () => clearTimeout(timer);
    }, [hoveredMarker]);
    const onMapTypeIdChangeHandler = () => {
        setMapType(map?.getMapTypeId());
    };
    if (loadError)
        return _jsx("div", { "data-test": "loading_map_state", children: "Error..." });
    if (!isLoaded)
        return _jsx("div", { "data-test": "loading_map_state", children: "Loading..." });
    return (_jsx("div", { "data-test": "custom-google-map", children: mapDelay && (_jsxs(GoogleMap, { center: mapCenter, id: "google-map", mapContainerClassName: "w-full h-[562px]", onLoad: onLoad, onMapTypeIdChanged: onMapTypeIdChangeHandler, options: {
                fullscreenControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },
                gestureHandling: "greedy",
                mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
                mapTypeId: mapType || google.maps.MapTypeId?.ROADMAP,
                scrollwheel: true,
                streetViewControl: false,
                zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
            }, zoom: 8, children: [_jsx(MarkerClusterer, { maxZoom: mapType === "roadmap" ? 22 : 19, styles: [
                        {
                            height: 53,
                            textColor: "white",
                            url: assetsCluster,
                            width: 53
                        }
                    ], children: (clusterer) => _jsx("div", { children: renderMarkers(clusterer) }) }), infoWindowOpen && (_jsx(InfoWindow, { onCloseClick: () => setInfoWindowOpen(null), options: {
                        pixelOffset: new window.google.maps.Size(0, -50)
                    }, position: {
                        lat: infoWindowOpen.location[0],
                        lng: infoWindowOpen.location[1]
                    }, children: _jsxs("div", { className: "text-sm pt-4", children: [infoWindowOpen.assetName && (_jsxs("div", { children: [_jsxs("span", { className: "font-bold text-base", "data-test": "info-window-asset-name-label", children: ["Name :", " "] }), _jsx("span", { "data-test": "info-window-asset-name", children: infoWindowOpen.assetName })] })), infoWindowOpen.soc && (_jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "font-bold", "data-test": "soc", children: "SOC :" }), _jsxs("span", { className: "ml-1", "data-test": "soc-value", children: [Math.round(infoWindowOpen.soc), "%\u00A0"] }), getBatteryIcon(Number(infoWindowOpen?.soc))] })), infoWindowOpen.timestamp && (_jsxs("div", { children: [_jsxs("span", { className: "font-bold", "data-test": "last-reported-time", children: ["Last Reported Time :", " "] }), _jsxs("span", { "data-test": "last-reported-time-zone-value", children: [format(getLocalTime(infoWindowOpen.timestamp, siteOffset), "yyyy-MM-dd HH:mm"), " ", siteTimezone] })] }))] }) }))] })) }));
};
export default CustomGoogleMap;
