import { jsx as _jsx } from "react/jsx-runtime";
import { CatIconBatteryFull } from "blocks-react/bedrock/components/Icons/BatteryFull/BatteryFull";
import { CatIconBatteryLow } from "blocks-react/bedrock/components/Icons/BatteryLow/BatteryLow";
import { CatIconBatteryMedium } from "blocks-react/bedrock/components/Icons/BatteryMedium/BatteryMedium";
export const getBatteryIcon = (soc) => {
    const BATTERY_FIFTY = 50;
    const BATTERY_FIFTEEN = 15;
    if (soc >= BATTERY_FIFTY) {
        return _jsx(CatIconBatteryFull, { color: "success", "data-test": "CatIconBatteryFull" });
    }
    else if (soc < BATTERY_FIFTY && soc >= BATTERY_FIFTEEN) {
        return _jsx(CatIconBatteryMedium, { color: "knockout-brand", "data-test": "CatIconBatteryMedium" });
    }
    else if (soc < BATTERY_FIFTEEN && soc > 0) {
        return _jsx(CatIconBatteryLow, { color: "error", "data-test": "CatIconBatteryLow" });
    }
    else {
        return _jsx(CatIconBatteryMedium, { color: "error", "data-test": "CatIconError" });
    }
};
